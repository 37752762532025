import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { useToast } from 'vue-toastification/composition'
import { computed, onBeforeMount, provide, reactive, watch } from '@vue/composition-api'
import { getCodeLabel, getCodeOptions } from '@core/utils/filter'
import { add, multiply } from '@core/utils/math/utils'
import { getUserData } from '@/auth/utils'

export default function useEdit() {
  const toast = useToast()
  const {
    router,
    route
  } = useRouter()


  const init = async () => {

    state.id = route.value.query.id
    state.readonly = route.value.params.readonly
    provide('_disabled', state.readonly)
    if (state.id) {
      await methods.edit()
    }else {
      state.outbound.loaded = true
    }
  }

  onBeforeMount(async () => {
    await init()
  })

  const methods = {
    showSingleModal(modalName) {
      if(modalName === '终端客户'){
        state.companyType = 'OffSalesChannelCustomer'
      }
      state.modalName = modalName
      state.singleModal.show()
    },
    onSelectSingle() {
      let data = {}
      switch (state.modalName) {
        case '终端客户': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            state.outbound.channel_id = data[0].company_id
            state.outbound.channel_name = data[0].company_name
          }
          break
        }
      }
    },


    async edit() {
      const res = await store.dispatch('outbound/edit', { id: state.id })
      if (res.data.code === 0){
        state.outbound = Object.assign({}, state.outbound, res.data.data)
        state.outbound.creator_name = getCodeLabel('user',state.outbound.creator)
        if(state.outbound.type==4){
          page.basicInfo.channel_name.attrs.showOr=true
        }
      }else {
        toast.error(res.data.data)
      }
    },

    cancel() {
      router.go(-1)
    },

    async save(status) {
      const success = await state.observer.validate()
      if (!success) {
        toast.error('请正确填写必填字段')
        return
      }
      const editableData = state.itemList.editableData
      const editingData = Object.values(editableData)
        .filter(item => !item || !item.readonly)
      if (editingData.length > 0) {
        toast.error('请保存明细列表未保存数据')
        return
      }

      if (state.itemList.filterItems.length === 0){
        toast.error('请选择商品，无明细不可保存')
        return
      }
      const items = state.itemList.items
      const params = { ...state.outbound }
      params.status=status
      params.items = items
      store.dispatch('outbound/save', params)
        .then(res => {
          if (res.data.code === 0) {
            toast.success('数据已保存!')
            router.push({ name: 'apps-outbound-list' })
          } else {
            toast.error(res.data.data)
          }
        })
    },

    onUploaded(id, attachment, result) {
      state.outbound[id] = result
    },
  }

  const state = reactive({
    id: 0,
    user:undefined,
    inbound_item_id:undefined,
    readonly: false,
    outbound: reactive({
      outbound_id: undefined,
      outbound_no: undefined,
      warehouse_id: undefined,
      warehouse_name: undefined,
      type: '',
      remark: undefined,
      attachments: undefined,
      channel_id:undefined,
      channel_name:undefined,

    }),

    itemList: undefined,

    observer: undefined,
    companyType:undefined,
    modalName:undefined,
    singleModal:'singleModal',
  })

  state.user = getUserData()

  const page = reactive({
    basicInfo: {
      outbound_no: {
        type: 'input',
        attrs: {
          id: 'outbound_no',
          label: '出库编号',
          readonly: true,
          placeholder: '保存后自动填充',
          showOr:true,
        },
      },
      warehouse_id: {
        type: 'select',
        attrs: {
          showOr:true,
          id: 'warehouse_id',
          label: '出库仓库',
          clearable:false,
          options: computed(()=>["1","2"].includes(state.user.level)?getCodeOptions('warehouse'):getCodeOptions('warehouse').filter(option=>getCodeLabel('user_warehouse',state.user.user_id).includes(option.value))),
          rules: 'required',
          reduce: option => parseInt(option.value)
        },
      },
      type: {
        type: 'select',
        attrs: {
          id: 'type',
          label: '出库类型',
          clearable:false,
          optionsType: "outbound_type",
          rules: 'required',
          showOr:true
        },
      },
      channel_name: {
        type: 'input',
        attrs: {
          id: 'channel_name',
          label: '终端客户',
          readonly: true,
          placeholder: '点击搜索终端客户',
          showOr:false,
          rules: 'required',
        },
        on: {
          click: () => methods.showSingleModal('终端客户')
        }
      },
    },

  })


  watch(() => state.outbound.warehouse_id, () => {
    state.outbound.warehouse_name = getCodeLabel('warehouse',state.outbound.warehouse_id)
    //改变仓库  清空明细列表
    state.itemList.items = []
  },
  )
  watch(() => state.outbound.type, () => {
        if(state.outbound.type==4){
          page.basicInfo.channel_name.attrs.showOr=true
        }else{
          state.outbound.channel_id=0
          state.outbound.channel_name=""
          page.basicInfo.channel_name.attrs.showOr=false
        }
      },
  )

  return {
    state,
    methods,
    page,
  }
}
