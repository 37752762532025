<template>
  <b-card
  >
    <!-- form -->
    <b-form id="financePaymentForm" class="edit-form mt-2">
      <ValidationObserver ref="observer">
        <b-row>
          <b-col md="12">
            <div class="inner-card-title">基本信息</div>
          </b-col>
          <b-col v-for="(value,name,index) in basicInfo" :key="index + '1'" md="4">
            <xy-form :prop-data="value" v-model="outbound[name]"></xy-form>
          </b-col>
          <b-col md="12">
            <div class="inner-card-title">明细列表</div>
          </b-col>
          <b-col>
            <outbound-item-list ref="itemList" :id="id" :warehouse_id="outbound.warehouse_id" :purchaseTeamUserId="isEmpty(outbound.creator)?user.user_id:outbound.creator"
            >
            </outbound-item-list>
          </b-col>

        </b-row>
        <b-row >
          <b-col md="12">
            <div class="inner-card-title">其他信息</div>
          </b-col>
          <b-col md="12">
            <xy-textarea label-cols="1" id="remark" label="备注" :val.sync="outbound.remark" rules="required"/>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload v-if="outbound.loaded" theme="files"
                                 :readonly="readonly"
                                 attachment_id="attachments"
                                 :id="outbound.attachments"
                                 object_type="outbound"
                                 :object_id="outbound.outbound_id"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </ValidationObserver>

      <b-row>
        <b-col
            cols="12"
            class="mt-50"
        >
          <xy-button
              variant="primary"
              class="mr-1"
              @click="save(1)"
          >
            保存
          </xy-button>

          <xy-button
                  variant="primary"
                  class="mr-1"
                  @click="save(2)"
          >
            提交
          </xy-button>

          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
    >

      <company-select
              ref="companySelect" :companyType="companyType" v-if="modalName === '终端客户' "
      />



    </b-modal>
  </b-card>
</template>

<script>
import { onUnmounted, toRefs } from '@vue/composition-api'
import store from '@/store'
import {getCode, getCodeColor, getCodeLabel, getCodeOptions, isEmpty, toDate, toTime} from '@core/utils/filter'
import CompanySelect from '@/views/apps/company/CompanySelect'
import useEdit from './useEdit'
import XyForm from '@/views/components/xy/XyFormShow'
import XyTextarea from '@/views/components/xy/XyTextarea'
import XyButton from '@/views/components/xy/XyButton'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import PurchaseReturnboundItemList from '@/views/apps/purchasereturnbounditem/PurchaseReturnboundItemList'
import outboundStore from '@/views/apps/outbound/outboundStore'
import OutboundItemList from '@/views/apps/outbounditem/OutboundItemList'
import {getUserData} from "@/auth/utils";

export default {
  components: {
    OutboundItemList,
    PurchaseReturnboundItemList,
    AttachmentUpload,
    XyButton,
    XyTextarea,
    XyForm,
    CompanySelect
  },
  setup() {
    // Register module
    if (!store.hasModule('outbound')) store.registerModule('outbound', outboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('outbound')) store.unregisterModule('outbound')
    })

    const {
      methods: useMethods,
      state: useState,
      page: usePage,
    } = useEdit()

    return {
      ...toRefs(useState),
      ...toRefs(usePage),
      ...useMethods,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime,
      toDate,
      isEmpty,
    }
  },
  created() {
    this.user = getUserData()
  },
}
</script>

<style lang="scss">
</style>
